<template>
  <div class="container tournament-list-container">
    <h2 class="font-24 heading color-33 m-b-30">{{ $t("record.default.pageTitle") }}</h2>
    <div class="card">
      <div class="card-header">
        <img src="../assets/images/racket-gray.svg">
        <span class="mx-2">{{ $t("record.default.tournamentList") }}</span>
      </div>
      <div v-if="tournament.length > 0" class="card-body tournament-card-body">
        <div v-for="obj in tournament"
             v-bind:key="obj.tournamentId" class="row">
          <div class="col-lg-6 col-sm-12 d-flex align-items-center first-area">
            <div class="tournament-status">
              <span class="d-flex align-items-center" v-html="showTournamentStatus(obj.status)"></span>
            </div>
            <div class="col-12 col-sm-10 col-lg-9 d-flex align-items-center">
                <span class="card-name">
                  <i v-if="obj.status===1" class="fa fa-circle end-fa-circle end-fa-circle-upcoming"></i>
                  <i v-if="obj.status===2" class="fa fa-circle end-fa-circle end-fa-circle-ongoing"></i>
                  <i v-if="obj.status===3" class="fa fa-circle end-fa-circle end-fa-circle-ended"></i>
                  <router-link :to="cta(obj)">
                    {{ obj.tournamentName }}
                  </router-link>
                </span>
            </div>
          </div>
          <div class="row col-lg-6 col-sm-12 col-12 second-area">
            <div class="col-lg-2 col-sm-4 col-12 d-flex align-items-center div-card-status">
                <span class="card-status align-items-center text-clamp-1">
                  <svg fill="#bbb" height="13" viewBox="0 0 11.218 12.39" width="11.13"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.135,12.59A.949.949,0,0,1,.2,11.557c0-1.032.935-4.13,5.609-4.13s5.609,3.1,5.609,4.13a.949.949,0,0,1-.935,1.032ZM5.809,6.395a2.576,2.576,0,0,0,1.073-.236,2.8,2.8,0,0,0,.91-.671,3.137,3.137,0,0,0,.608-1,3.4,3.4,0,0,0,0-2.371,3.137,3.137,0,0,0-.608-1,2.8,2.8,0,0,0-.91-.671A2.576,2.576,0,0,0,5.809.2a2.677,2.677,0,0,0-1.983.907A3.269,3.269,0,0,0,3,3.3a3.269,3.269,0,0,0,.821,2.19,2.677,2.677,0,0,0,1.983.907Z"
                        transform="translate(-0.2 -0.2)"/>
                  </svg>
                  {{ obj.userRole }}
                </span>
            </div>
            <div class="col-lg-8 col-sm-7 col-10 d-flex align-items-center div-card-date">
                <span class="card-date">
                  <i class="fa fa-calendar-check"></i>
                  <span class="inputPeriod">{{ $t("record.default.inputPeriod") }}:</span> {{
                    getFormattedDate(obj.inputStartAt)
                  }}〜{{ getFormattedDate(obj.inputEndAt) }}
                </span>
            </div>
            <div class="col-lg-2 col-sm-1 col-2 d-flex align-items-center div-card-info">
              <router-link :to="cta(obj)">
                <i class="fa fa-info-circle"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="card-body">
        <p>
          {{ $t("record.recordList.noData") }}
        </p>
      </div>
    </div>
  </div>

</template>

<script>
import Common from '../assets/jsipclient/common'
import Helper from '../assets/jsipclient/helper'
import {mapGetters} from 'vuex'

export default {
  name: "Record",
  data() {
    return {
      tournament: {},
      errors: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  mounted() {
    this.getRecord()
    window.scrollTo({top: 0, behavior: "auto"})
  },
  watch: {
    async $route(to, from) {
      // Called from back or next
      await this.getRecord()
    }
  },
  methods: {
    async getRecord() {
      const api = new IpApi.UserApi(Helper.apiConfig(this.config.token))
      this.loading = true
      try {
        const tournament = await api.usersUserIdTournamentsGetAsync(this.config.userId)
        if (tournament.items) {
          this.tournament = tournament.items
          this.loading = false
        }
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});

        } else {
          Common.handleAPIError(error)
        }
      } finally {
        this.loading = false
      }
    },
    showTournamentStatus(tournamentStatus, extraCss = '') {
      var statusText = ""
      switch (tournamentStatus) {
        case 1:
          statusText = this.$t("home.default.comingSoon")
          break;
        case 2:
          statusText = this.$t("home.default.duringPeriod")
          break;
        case 3:
          statusText = this.$t("home.default.end")
          break;
      }
      return Helper.showTournamentStatus(tournamentStatus, extraCss, statusText)
    },
    getFormattedDate(date) {
      return Common.dateFormatConvert(date)
    },
    cta(obj) {
      return {
        name: "UserRecord",
        params: {
          tournamentId: Number(obj.tournamentId)
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/tournaments";

.tournament-card-body {
  > .row:not(:last-child) {
    border-bottom: 1px solid #D3D8EA;
  }

  color: #333333;
}

@media only screen and (max-width: 576px) {
  .heading {
    padding-left: 19px !important;
    padding-right: 19px !important;
  }

  h2 {
    margin-top: 40px;
  }
}
</style>
